import React from 'react';
import { Container } from '../../container/container';
import { AspectRatioBox } from '../../container/aspect-ratio-box';
import { AssetImg } from '../../asset/asset-img';
import clsx from 'clsx';
import { isBlank } from '../../../lib/helpers';
import styles from './testimonial-section.module.scss';

export function TestimonialSection({ node }) {
  const { testimonials = [] } = node;
  return (
    <Container variant="thin" className={styles.container}>
      {testimonials.map((testimonial, index) => {
        const {
          personImage,
          personName,
          personRole,
          text,
          id,
          client: { name, city },
        } = testimonial;

        let clientInfo = '';
        if (!isBlank(name)) {
          clientInfo = `- ${name}`;
          clientInfo += isBlank(city) ? '' : `, ${city}`;
        }

        return (
          <div key={id} className={clsx(styles.testimonialItem, index % 2 !== 0 && styles.reverse)}>
            <div className={styles.textContainer}>
              <p className={styles.text}>{text}</p>
              <p className={styles.name}>{personName}</p>
              <p className={styles.role}>
                {personRole} {clientInfo}
              </p>
            </div>
            <div className={styles.imageContainer}>
              {/* Width ratio is calculated from actual design */}
              <AspectRatioBox width={1.123} height={1} className={styles.image}>
                <AssetImg asset={personImage.asset} className={styles.image} />
              </AspectRatioBox>
            </div>
          </div>
        );
      })}
    </Container>
  );
}
