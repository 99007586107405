import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from '../../container/container';
import { AssetImg } from '../../asset/asset-img';
import styles from './clients-section.module.scss';

export function ClientsSection({ node }) {
  const { title } = node;

  const { allSanityClient } = useStaticQuery(graphql`
    {
      allSanityClient(sort: { order: DESC, fields: logo___asset___fluid___aspectRatio }) {
        nodes {
          _id
          name
          website
          logo {
            asset {
              id
              localFile {
                publicURL
                childImageSharp {
                  fluid(
                    maxHeight: 75
                    srcSetBreakpoints: [200, 480, 768]
                    traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#08080d", color: "#c62d34" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    aspectRatio
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { nodes } = allSanityClient;

  return (
    <Container variant="thin">
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.logosContainer}>
        {nodes.map((client) => {
          const { logo, name } = client;
          return (
            logo &&
            logo.asset && (
              <div key={`${name}-key`} className={styles.logoItem}>
                <AssetImg asset={logo.asset} alt={`${name}-logo`} className={styles.image} nonStretch={true} />
              </div>
            )
          );
        })}
      </div>
    </Container>
  );
}
