import React from 'react';
import { Container } from '../../container/container';
import { LinkButton } from '../../button/link-button';
import { AspectRatioBox } from '../../container/aspect-ratio-box';
import { AssetImg } from '../../asset/asset-img';
import { getUrlFromNavItem } from '../../../lib/helpers';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './hero-section.module.scss';

export function HeroSection({ node }) {
  const {
    title,
    subtitle,
    cta: {
      label,
      reference: { _id: linkPageId, _type: linkType, slug = {} },
    },
    image,
  } = node;

  const {
    siteSettings: {
      startPage: { id: startPageId },
    },
  } = useStaticQuery(
    graphql`
      query {
        siteSettings: sanitySiteSettings(_id: { eq: "siteSettings" }) {
          startPage {
            id: _id
            slug {
              current
            }
          }
        }
      }
    `
  );

  const link = getUrlFromNavItem({ id: linkPageId, type: linkType, slug: slug.current }, startPageId);

  return (
    <Container className={styles.container}>
      <div className={styles.heroVisualWrapper}>
        <Container component="div" variant="thin" className={styles.heroVisual}>
          <div className={styles.text}>
            {title && <h1>{title}</h1>}
            {subtitle && <p>{subtitle}</p>}
            {label && <LinkButton to={link}>{label}</LinkButton>}
          </div>
          <div className={styles.visual}>
            {image && (
              <AspectRatioBox width={540} height={597}>
                <AssetImg asset={image.asset} alt={image.alt} className={styles.image} />
              </AspectRatioBox>
            )}
          </div>
        </Container>
      </div>
    </Container>
  );
}
