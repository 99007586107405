import React from 'react';
import { Container } from '../../container/container';
import { BlockContent } from '../../block-content/block-content';
import styles from './cooperation-services-section.module.scss';

export function CooperationServicesSection({ node = {} }) {
  const { body, cooperation = [] } = node;
  return (
    <Container variant="thin" className={styles.container}>
      <div className={styles.topSection}>
        <BlockContent blocks={body} />
      </div>
      <div className={styles.secondSection}>
        <ul>
          {cooperation.map(({ _key: key, body }) => (
            <li key={`list-item-${key}`}>
              <BlockContent blocks={body} />
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
}
