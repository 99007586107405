import React from 'react';
import { Container } from '../container/container';
import { PageTitle } from '../page-title/page-title';
import { AssetImg } from '../asset/asset-img';
import styles from './page-header-section.module.scss';

export function PageHeaderSection({ title, mainImage }) {
  return (
    <>
      <PageTitle title={title} />
      {mainImage.asset && (
        <Container className={styles.container}>
          <AssetImg asset={mainImage.asset} className={styles.image} alt={mainImage.alt} />
        </Container>
      )}
    </>
  );
}
