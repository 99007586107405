import React, { useState } from 'react';
import { Container } from '../../container/container';
import { useSprings, animated as a, useTrail } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';
import styles from './company-facts-section.module.scss';

export function CompanyFactsSection({ node }) {
  const { key, companyFacts } = node;
  const [isVisible, setIsVisible] = useState(false);

  const countProps = useSprings(
    companyFacts.length,
    companyFacts.map((fact) => ({
      num: isVisible ? fact.count : 0,
      from: {
        num: 0,
      },
      config: { mass: 100, friction: 200, tension: 200, clamp: true },
    }))
  );

  const [trail, setTrail] = useTrail(companyFacts.length, () => ({
    transform: `translateY(2em)`,
    opacity: 0,
  }));

  if (isVisible) {
    setTrail({
      transform: `translateY(0em)`,
      opacity: 1,
    });
  }

  return (
    <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)} active={!isVisible}>
      <Container variant="thin" key={`section-${key}`} className={styles.container}>
        {companyFacts.map((fact, index) => {
          const { title, equality, key } = fact;
          return (
            <a.h2 style={trail[index]} key={`company-fact-${index}-${key}`} className={styles.fact}>
              {`${equality === 'more' ? '>' : ''} `}
              <a.span>{countProps[index].num.interpolate((val) => Math.floor(val))}</a.span>
              <br />
              <span className={styles.title}>{title}</span>
            </a.h2>
          );
        })}
      </Container>
    </VisibilitySensor>
  );
}
