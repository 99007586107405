import React from 'react';
import clsx from 'clsx';
import { Container } from '../../container/container';
import { ExternalLinkButton } from '../../button/external-link-button';
import styles from './cta-section.module.scss';

export function CtaSection({ node }) {
  const { label, text, externalUrl, linkType } = node;
  const linkPrefix = linkType === 'url' ? '' : `${linkType}:`;
  return (
    <Container variant="wide" className={styles.container}>
      <Container variant="thin" className={styles.innerContainer}>
        <h3 className={styles.text}>{text}</h3>
        <ExternalLinkButton
          className={clsx(styles.button, styles.action)}
          href={`${linkPrefix}${externalUrl}`}
          targetBlank={false}
        >
          {label}
        </ExternalLinkButton>
      </Container>
    </Container>
  );
}
