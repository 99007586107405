import React from 'react';
import { MarketingOfferSection } from './marketing-offer-section/marketing-offer-section';
import { HeroSection } from './hero-section/hero-section';
import { HeroBlockTextSection } from './hero-block-text-section/hero-block-text-section';
import { BlogTeaserSection } from './blog-teaser-section/blog-teaser-section';
import { CompanyFactsSection } from './company-facts-section/company-facts-section';
import { ThinQuoteSection } from './thin-quote-section/thin-quote-section';
import { WideQuoteSection } from './wide-quote-section/wide-quote-section';
import { BlockContentSection } from './block-content-section/block-content-section';
import { BlockTextImageSection } from './block-text-image-section/block-text-image-section';
import { ClientsSection } from './clients-section/clients-section';
import { TestimonialSection } from './testimonial-section/testimonial-section';
import { CtaSection } from './cta-section/cta-section';
import { IconListSection } from './icon-list-section/icon-list-section';
import { CooperationServicesSection } from './cooperation-services-section/cooperation-services-section';
import { InstagramFeedSection } from './instagram-feed-section/instagram-feed-section';

export function renderSection(sectionNode) {
  const sectionKey = `section-${sectionNode._key}`;
  switch (sectionNode._type) {
    case 'heroSection':
      return <HeroSection key={sectionKey} node={sectionNode} />;
    case 'companyFactsSection':
      return <CompanyFactsSection key={sectionKey} node={sectionNode} />;
    case 'thinQuoteSection':
      return <ThinQuoteSection key={sectionKey} node={sectionNode} />;
    case 'blogTeaserSection':
      return <BlogTeaserSection key={sectionKey} node={sectionNode} />;
    case 'wideQuoteSection':
      return <WideQuoteSection key={sectionKey} node={sectionNode} />;
    case 'blockContentSection':
      return <BlockContentSection key={sectionKey} node={sectionNode} />;
    case 'marketingOfferSection':
      return <MarketingOfferSection key={sectionKey} node={sectionNode} />;
    case 'blockTextImageSection':
      return <BlockTextImageSection key={sectionKey} node={sectionNode} />;
    case 'clientsSection':
      return <ClientsSection key={sectionKey} node={sectionNode} />;
    case 'testimonialSection':
      return <TestimonialSection key={sectionKey} node={sectionNode} />;
    case 'ctaSection':
      return <CtaSection key={sectionKey} node={sectionNode} />;
    case 'heroBlockTextSection':
      return <HeroBlockTextSection key={sectionKey} node={sectionNode} />;
    case 'iconListSection':
      return <IconListSection key={sectionKey} node={sectionNode} />;
    case 'cooperationServicesSection':
      return <CooperationServicesSection key={sectionKey} node={sectionNode} />;
    case 'instagramFeedSection':
      return <InstagramFeedSection key={sectionKey} node={sectionNode} />;
    default:
      return null;
  }
}
