import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import styles from './button.module.scss';

export function LinkButton({ className, children, ...other }) {
  return (
    <Link className={clsx(styles.button, className)} {...other}>
      {children}
    </Link>
  );
}
