import React from 'react';
import clsx from 'clsx';
import { BlockContent } from '../block-content/block-content';
import { AssetImg } from '../asset/asset-img';
import styles from './iconlist.module.scss';

export const IconList = ({ items, className, ...other }) => {
  if (!items) {
    return null;
  }

  return (
    <ul {...other} className={clsx(styles.iconList, className)}>
      {items.map(({ _key: key, body, icon: { asset, alt } }) => (
        <li key={`list-item-${key}`}>
          <div className={styles.iconWrapper}>
            {asset && (
              <AssetImg
                asset={asset}
                alt={alt}
                width={asset.metadata.dimensions.width}
                height={asset.metadata.dimensions.height}
              />
            )}
          </div>
          {body && (
            <div className={styles.textWrapper}>
              <BlockContent blocks={body} />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};
