import React from 'react';
import { Container } from '../../container/container';
import { AssetImg } from '../../asset/asset-img';
import { AspectRatioBox } from '../../container/aspect-ratio-box';
import styles from './thin-quote-section.module.scss';

export function ThinQuoteSection({ node = {} }) {
  const { image = {}, subtitle } = node;

  return (
    <Container className={styles.container}>
      <div className={styles.boundary}>
        <div className={styles.imageContainer}>
          {image.asset && (
            <AspectRatioBox width={869} height={817}>
              <AssetImg asset={image.asset} alt={image.alt} className={styles.image} />
            </AspectRatioBox>
          )}
        </div>
        <p className={styles.textContainer}>{subtitle}</p>
      </div>
    </Container>
  );
}
