import React from 'react';
import { Container } from '../../container/container';
import BlockText from '../../block-content/block-text';
import { IconList } from '../../../components/iconlist/icon-list';
import styles from './marketingsection.module.scss';

export function MarketingOfferSection({ node = {} }) {
  const { _key: key, title, description, services } = node;
  return (
    <Container variant="thin" key={`section-${key}`} className={styles.container}>
      <div>
        <div className={styles.text}>
          <h2>{title}</h2>
          <BlockText blocks={description} />
        </div>
      </div>
      <div className={styles.iconListWrapper}>
        <IconList items={services} />
      </div>
    </Container>
  );
}
