import React from 'react';
import { Container } from '../../container/container';
import { IconList } from '../../../components/iconlist/icon-list';
import BlockText from '../../block-content/block-text';
import styles from './icon-list-section.module.scss';

export function IconListSection({ node = {} }) {
  const { title, description, list } = node;

  return (
    <Container variant="thin" className={styles.container}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && <BlockText blocks={description} />}
      {list && <IconList className={styles.iconList} items={list} />}
    </Container>
  );
}
