import React from 'react';
import { Container } from '../../container/container';
import { BlockContent } from '../../block-content/block-content';
import styles from './hero-block-text-section.module.scss';

export function HeroBlockTextSection({ node = {} }) {
  const { content } = node;

  return (
    <Container variant="thin" className={styles.container}>
      <div className={styles.hugeArrow}>
        <div className={styles.hugeArrowInner} />
      </div>
      <div className={styles.blockWrapper}>
        <BlockContent blocks={content} />
      </div>
    </Container>
  );
}
