import React from 'react';
import { Container } from '../../container/container';
import { InstagramFeed } from '../../instagram-feed/instagram-feed';
import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from '../../page-title/page-title';

export function InstagramFeedSection({ node: { title } }) {
  const {
    allInstagramContent: { edges: instaEdges },
  } = useStaticQuery(graphql`
    {
      allInstagramContent(sort: { order: DESC, fields: timestamp }, filter: { media_type: { eq: "IMAGE" } }) {
        edges {
          node {
            id
            media_id
            media_type
            caption
            localFile {
              childImageSharp {
                fluid(
                  maxHeight: 450
                  maxWidth: 450
                  quality: 50
                  srcSetBreakpoints: [300, 450]
                  traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#08080d", color: "#c62d34" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  aspectRatio
                  presentationWidth
                }
              }
            }
            imageGallery: localFile {
              childImageSharp {
                fluid(
                  maxWidth: 1920
                  quality: 90
                  srcSetBreakpoints: [300, 720, 1400]
                  fit: COVER
                  traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#08080d", color: "#c62d34" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  aspectRatio
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  `);

  const instaNodes = instaEdges ? instaEdges.map((edge) => ({ ...edge.node })) : [];
  const instaUserNode = {
    username: 'alex_hauk',
  };

  return (
    <>
      {instaNodes && instaNodes.length > 0 && (
        <>
          {title && <PageTitle title={title} />}
          <Container variant="wide">
            <InstagramFeed items={instaNodes} profile={instaUserNode} />
          </Container>
        </>
      )}
    </>
  );
}
