import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { InstagramDetailsGallery } from './instagram-details-gallery';
import { getExcerpt } from '../../lib/helpers';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './instagram-feed.module.scss';

const NextArrow = ({ onClick, className }) => {
  return <div onClick={onClick} className={`${className} ${styles.customNext}`} />;
};

const PrevArrow = ({ onClick, className }) => {
  return <div onClick={onClick} className={`${className} ${styles.customPrev}`} />;
};

const gallerySettings = {
  dots: false,
  infinite: false,
  speed: 500,
  swipeToSlide: false,
  center: false,
  initialSlide: 0,
  slidesPerRow: 1,
  slidesToScroll: 6,
  slidesToShow: 6,
  rows: 1,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        swipe: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 2,
        swipe: true,
        arrows: false,
        dots: false,
      },
    },
  ],
};

/**
 * @return {null}
 */
export function InstagramFeed({ items = [], profile = {} }) {
  const [isMounted, setIsMounted] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [isShowDetails, setIsShowDetails] = useState(false);

  const handleShowImageDetails = (startIndex) => {
    //set startIndex for the react-image-gallery
    //set boolean true to show imageShowDetails

    setStartIndex(startIndex);
    setIsShowDetails(true);
  };

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  if (isMounted) {
    return (
      <>
        <Slider {...gallerySettings} className={styles.slider}>
          {items.length > 0 &&
            items.map(({ id, localFile, likes, caption }, index) => (
              <Slide
                key={`insta-image-${index}`}
                id={id}
                localFile={localFile}
                caption={caption}
                index={index}
                handleShowImageDetails={handleShowImageDetails}
              />
            ))}
        </Slider>
        {isShowDetails && (
          <InstagramDetailsGallery
            startIndex={startIndex}
            setIsShowDetails={setIsShowDetails}
            items={items}
            profile={profile}
          />
        )}
      </>
    );
  }

  return null;
}

function Slide({ id, localFile, likes, caption, index, handleShowImageDetails, ...otherProps }) {
  return (
    <div {...otherProps}>
      <div className={styles.imageWrapper} onClick={() => handleShowImageDetails(index)}>
        <Img fluid={localFile.childImageSharp.fluid} alt="instagram-images" />
        <div className={styles.caption}>
          <div className={styles.instagramPost}>
            <div className={styles.description}>{getExcerpt(caption, 90).shortText}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
