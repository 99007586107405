import React from 'react';
import clsx from 'clsx';
import { Container } from '../../container/container';
import { BlockContent } from '../../block-content/block-content';
import { AspectRatioBox } from '../../container/aspect-ratio-box';
import { AssetImg } from '../../asset/asset-img';
import styles from './block-text-image-section.module.scss';

export function BlockTextImageSection({ node }) {
  const { content, image = {}, imagePlacement } = node;

  return (
    <Container
      variant="thin"
      className={clsx(
        styles.container,
        imagePlacement === 'right' && styles.right,
        imagePlacement === 'left' && styles.left
      )}
    >
      <div className={styles.imageContainer}>
        <AspectRatioBox width={1} height={1} className={styles.imageWrapper}>
          <AssetImg asset={image.asset} alt={image.alt} className={styles.image} />
        </AspectRatioBox>
      </div>
      <BlockContent blocks={content} className={styles.text} />
    </Container>
  );
}
