import React from 'react';
import { graphql } from 'gatsby';
import { isFluidImage } from '../lib/helpers';
import { LayoutContent } from '../components/layout/layout-content';
import { renderSection } from '../components/sections/section-renderer';
import { PageHeaderSection } from '../components/page-header-section/page-header-section';
import AssetContext from '../contexts/AssetContext';
import { SEO } from '../components/seo';

const ContentPage = ({ data = {} }) => {
  const { page = {}, assets = [] } = data;

  // console.log('PAGE CONTEXT', pageContext);

  const { sections, description, keywords, title, mainImage = {} } = page;
  const assetContext = { assets };
  const showPageHeader = !!(isFluidImage(mainImage) && title);

  return (
    <AssetContext.Provider value={{ assetContext }}>
      <LayoutContent>
        <SEO title={title} description={description} keywords={keywords} />
        {showPageHeader && <PageHeaderSection title={title} mainImage={mainImage} />}
        {sections && sections.map((section) => renderSection(section))}
      </LayoutContent>
    </AssetContext.Provider>
  );
};

export default ContentPage;

export const query = graphql`
  query ContentPage($id: String!, $imageAssetIds: [String]) {
    page: sanityPage(_id: { eq: $id }) {
      id: _id
      sections: _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        asset {
          id
          localFile {
            publicURL
            childImageSharp {
              fluid(
                maxWidth: 1780
                srcSetBreakpoints: [340, 768, 1780]
                traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#08080d", color: "#c62d34" }
              ) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            extension
          }
          extension
        }
        alt
      }
      description
      keywords
      title
    }

    assets: allSanityImageAsset(filter: { id: { in: $imageAssetIds } }) {
      nodes {
        id: _id
        localFile {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 1780
              srcSetBreakpoints: [340, 768, 1780]
              traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#08080d", color: "#c62d34" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          extension
        }
      }
    }
  }
`;
