import React, { useContext } from 'react';
import { Container } from '../../container/container';
import BackgroundImage from 'gatsby-background-image';
import AssetContext from '../../../contexts/AssetContext';
import { findImageAssetByRef, isBlank, isFluidAsset } from '../../../lib/helpers';
import styles from './wide-quote-section.module.scss';
import { useMedia } from '../../../hooks/useMedia';

export function WideQuoteSection({ node }) {
  const { image = {}, title, subtitle, textBoxColor, textBoxPlacement } = node;
  const { assetContext } = useContext(AssetContext);

  const isNotMobile = useMedia(['(min-width: 768px)'], [true], false);
  const withText = !(isBlank(title) && isBlank(subtitle));

  const localFile = isFluidAsset(image.asset)
    ? image.asset.localFile
    : findImageAssetByRef(assetContext.assets, image.asset._ref || image.asset._id);

  const textOutput = (
    <>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.subTitle}>{subtitle}</p>
    </>
  );
  return (
    <div className={!isNotMobile ? styles.mobileContainer : ''}>
      <BackgroundImage Tag="div" className={styles.background} fluid={localFile.childImageSharp.fluid}>
        <Container
          className={styles.container}
          variant="thin"
          style={{
            justifyContent:
              textBoxPlacement !== 'center' ? (textBoxPlacement === 'left' ? 'flex-start' : 'flex-end') : 'center',
          }}
        >
          {withText && isNotMobile && (
            <Container className={`${styles.textContainer} ${styles[textBoxColor]}`}>{textOutput}</Container>
          )}
        </Container>
      </BackgroundImage>
      {withText && !isNotMobile && (
        <Container className={`${styles.textContainer} ${styles.textContainerMobile} ${styles[textBoxColor]}`}>
          {textOutput}
        </Container>
      )}
    </div>
  );
}
