import React from 'react';
import { AssetImg } from '../asset/asset-img';
import { useKeenSlider } from 'keen-slider/react';

import 'keen-slider/keen-slider.min.css';
import styles from './instagram-details-gallery.module.scss';

const ProfileInfo = ({ profile = {} }) => {
  const { username } = profile;
  return (
    <div className={styles.slideProfile}>
      <span>
        {`${username}`}{' '}
        <a href={`https://instagram.com/${username}`} target="_blank" rel="noopener noreferrer">
          auf Instagram folgen
        </a>
      </span>
    </div>
  );
};

const InstaSlide = ({ asset, caption }) => (
  <div className={`keen-slider__slide ${styles.instaSlideContent}`}>
    <div className={styles.instaSlideImage}>
      <AssetImg
        asset={asset}
        alt="instagram-images"
        className={styles.instaSlideImageAsset}
        fadeIn={false}
        role="presentation"
      />
    </div>
    <div className={styles.instaSlideCaption}>
      <p>{caption}</p>
    </div>
  </div>
);

const keenOptions = {
  loop: true,
  centered: true,
  spacing: 24,
  slidesPerView: 1,
  mode: 'snap',
  breakpoints: {
    '(max-width: 768px)': {
      slidesPerView: 1,
      mode: 'snap',
      spacing: 15,
      centered: true,
      loop: true,
      rtl: true,
    },
    '(min-width: 1921px)': {
      slidesPerView: 1,
      spacing: 15,
    },
    '(min-width: 3000px)': {
      slidesPerView: 1,
    },
  },
};

export function InstagramDetailsGallery({ startIndex, setIsShowDetails, items = [], profile }) {
  const [sliderRef] = useKeenSlider({
    ...keenOptions,
    initial: startIndex,
  });

  return (
    <div
      className={styles.imageGalleryContainer}
      style={{ display: startIndex !== null ? 'block' : 'none' }}
      aria-hidden="true"
    >
      <span className={styles.closeIcon} onClick={() => setIsShowDetails(false)}>
        &times;
      </span>
      <div className={styles.instagramProfileMobile}>
        <ProfileInfo profile={profile} />
      </div>
      <div className={`keen-slider`} ref={sliderRef}>
        {items.map(({ imageGallery, caption }, index) => {
          const assetImg = { localFile: { ...imageGallery } };
          return <InstaSlide key={`insta-image-${index}`} asset={assetImg} caption={caption} />;
        })}
      </div>
    </div>
  );
}
